<template>
  <v-app id="inspire" v-if="$routeMeta">
    <HeaderBar
      :prominent="
        $route.name === 'Home' ? (prominent = true) : (prominent = false)
      "
    />
    <full-page
      :options="fullPageOptions"
      ref="fullpage"
      :skip-init="true"
      v-if="showFullPageLibrary"
    >
      <section v-for="route in getLinks" :key="route.slug" class="section">
        <keep-alive>
          <component :is="route.slug + 'Banner'"></component>
        </keep-alive>
      </section>
    </full-page>
    <ViewLayout v-else />
  </v-app>
</template>

<script>
import HeaderBar from "./components/HeaderBar";
import ViewLayout from "./components/ViewLayout";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "Mixart"
  },
  components: {
    HeaderBar,
    ViewLayout
  },
  watch: {
    $route() {
      if (
        this.$refs.fullpage &&
        this.getCurrentTransitionPosition !=
          this.$refs.fullpage.api.getActiveSection().index + 1
      ) {
        this.avoidTransitionRoutePush = true;
        this.$refs.fullpage.api.silentMoveTo(this.getCurrentTransitionPosition);
      }
    },
    showFullPageLibrary(val) {
      if (val) {
        this.componentsReady();
      } else {
        this.loadedFullPageLibrary = false;
      }
    }
  },
  data: () => {
    return {
      prominent: null,
      appContent: null,
      loadedFullPageLibrary: false,
      avoidTransitionRoutePush: false
    };
  },

  computed: {
    fullPageOptions() {
      return {
        licenseKey: "2169EE3C-EF3F446C-A483DBA8-D64886B0",
        afterLoad: this.afterLoad,
        afterRender: this.afterRender
      };
    },
    hideFooter() {
      return this.$routeMeta.meta.hideFooter;
    }
  },

  mounted() {
    this.componentsReady();
  },

  methods: {
    ...mapMutations(["setCurrentTransitionPosition"]),

    componentsReady() {
      if (!this.loadedFullPageLibrary && this.showFullPageLibrary) {
        this.loadedFullPageLibrary = true;
        this.$nextTick(() => {
          this.$refs.fullpage.init();
        });
      }
    },

    afterRender() {
      this.avoidTransitionRoutePush =
        this.$routeMeta.meta.transition.position > 1;
      this.$nextTick(() =>
        this.$refs.fullpage.api.silentMoveTo(
          this.$routeMeta.meta.transition.position
        )
      );
    },

    afterLoad(origin, destination, direction) {
      if (direction) {
        const position = direction === "down" ? 1 : -1;
        const getNewRouteComponent = this.getRouteComponentByPosition(position);
        if (getNewRouteComponent && !this.avoidTransitionRoutePush) {
          this.setCurrentTransitionPosition(
            this.$routeMeta.meta.transition.position + position
          );
          this.$router.push({
            name: getNewRouteComponent.slug,
            params: { locale: this.$route.params.locale }
          });
        } else if (this.avoidTransitionRoutePush) {
          this.avoidTransitionRoutePush = false;
        }
      }
    },

    getRouteComponentByPosition(position) {
      return this.getLinks.find(route => {
        return (
          route.meta.transition.position ===
          this.getCurrentTransitionPosition + position
        );
      });
    }
  }
};
</script>
