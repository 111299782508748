import Vue from "vue";
import Vuex from "vuex";
import contacts from "./modules/contacts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    contacts
  },
  state: {
    currentTransitionPosition: null,
    showFullPageLibrary: false,
    redirectTo: null,
    countries: [
      {
        flag: "us",
        locale: "en"
      },
      {
        flag: "do",
        locale: "es"
      }
    ],
    links: [
      {
        title: "menu.home",
        slug: "Home",
        url: "/home",
        overviewUrl: "/home",
        color: "grey",
        meta: {
          hideFooter: true,
          transition: {
            position: 1
          },
          active: {
            color: "grey"
          }
        },
        id: "#home"
      },
      {
        title: "menu.projects",
        slug: "Projects",
        url: "/projects",
        overviewUrl: "/projects/overview",
        meta: {
          transition: {
            position: 2
          },
          active: {
            color: "#662E8E"
          }
        },
        color: "purple accent-3",
        id: "#creatividad"
      },
      {
        title: "menu.services",
        slug: "Services",
        url: "/services",
        overviewUrl: "/services/overview",
        meta: {
          transition: {
            position: 3
          },
          active: {
            color: "#2C79BE"
          }
        },
        color: "blue",
        id: "#servicios"
      },
      {
        title: "menu.aboutUs",
        slug: "About",
        url: "/about-us",
        overviewUrl: "/about-us/overview",
        color: "red",
        meta: {
          transition: {
            position: 4
          },
          active: {
            color: "#A53423"
          }
        },
        id: "#nosotros"
      },
      {
        title: "menu.contactUs",
        slug: "Contact",
        url: "/contact-us",
        overviewUrl: "/contact-us/overview",
        meta: {
          transition: {
            position: 5
          },
          active: {
            color: "#237B3D"
          }
        },
        color: "green",
        id: "#contactanos",
        useButton: true
      }
    ],
    technologies: [
      {
        name: "Shopify",
        color: "#3B9B20",
        textcolor: "#ffffff"
      },
      {
        name: "JavaScript",
        color: "#FFE200",
        textcolor: "#000"
      },
      {
        name: "Vue.js",
        color: "#4fc08d",
        textcolor: "#ffffff"
      },
      {
        name: "Laravel",
        color: "#ff2d20",
        textcolor: "#ffffff"
      },
      {
        name: "WordPress",
        color: "#117ac9",
        textcolor: "#ffffff"
      }
    ],
    config: {
      blockRoutingOnScroll: false,
      onDetailSection: false,
      headerHeight: null
    }
  },
  mutations: {
    setCurrentTransitionPosition(state, position) {
      state.currentTransitionPosition = position;
    },

    showFullPageLibrary(state, data) {
      state.showFullPageLibrary = data;
    },

    blockScrollRouting(state, block) {
      state.config.blockRoutingOnScroll = block;
    },

    openDetailSection(state, isDetail) {
      state.config.onDetailSection = isDetail;
      state.config.blockRoutingOnScroll = isDetail;
    },
    updateHeaderHeight(state, height) {
      state.config.headerHeight = height;
    },
    redirectTo(state, redirectTo) {
      state.redirectTo = redirectTo;
    },
    handleRedirect(state, nextRouterFunc) {
      if (state.redirectTo) {
        nextRouterFunc(state.redirectTo);
        state.redirectTo = null;
      } else {
        nextRouterFunc();
      }
    }
  },
  getters: {
    getCurrentTransitionPosition: state => state.currentTransitionPosition,
    routingOnScrollIsBlock: state => state.config.blockRoutingOnScroll,
    getLinks: state => state.links,
    isOnDetailSection: state => state.config?.onDetailSection,
    showFullPageLibrary: state => state.showFullPageLibrary,
    headerHeight: state => state.config.headerHeight,
    getCountries: state => state.countries,
    getTechnology: state => name => {
      return state.technologies.find(technology => technology.name === name);
    }
  },
  actions: {}
});
