const messages = {
  contactUsMessage: "Take your projects to the next level",
  contactUsDescription:
    "There is no distance between us, <span>you just have to click</span>",
  name: "Name",
  message: "Message",
  phone: "Phone",
  email: "E-mail",
  address: "Address",
  btnSend: "Send",
  nameRequired: "Name is required",
  nameMaxLength: "Name must be less than 60 characters",
  emailRequired: "E-mail is required",
  emailIsValid: "E-mail must be valid",
  messageRules: "Message is required",
  messageMaxLength: "Message must be less than 450 characters",
  contactSuccessMessage: "Your message has been send sucessfully",
  contactSuccessDescription: "We will get in touch as soon as possible",
  contactErrorMessage: "We have an error!",
  contactErrorDescription: "Try again later",
  recaptchaMessages:
    'This site is protected by reCAPTCHA, the <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> from Google.'
};

export default messages;
