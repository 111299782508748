<template>
  <v-footer :fixed="false">
    <v-container class="py-lg-16 py-10 footer-size">
      <v-row justify="end" class="py-0">
        <v-col cols="12" xs="12" lg="4" class="d-lg-flex d-none">
          <v-img
            :max-width="170"
            :height="60"
            src="/logo.svg"
            contain
            to="/"
            class="ma-auto ma-lg-0"
          ></v-img>
        </v-col>
        <v-col cols="12" xs="12" lg="8" class="py-8">
          <v-row class="d-lg-flex justify-space-between d-none">
            <div
              v-for="(link, index) in getLinks"
              :key="index"
              class="d-flex  align-self-center align-center"
            >
              <v-btn
                color="white"
                text
                rounded
                class="my-2 font-weight-bold"
                :to="`/${$i18n.locale}${link.overviewUrl}`"
                plain
              >
                {{ $t(link.title) }}
              </v-btn>
              <span
                v-if="index + 1 < getLinks.length"
                style="color: "
                class="font-weight-regular text-white pl-6"
                >/</span
              >
            </div>
          </v-row>
          <v-row class="pt-lg-7">
            <v-col cols="12" xs="12" lg="6" class="py-0 pl-4">
              <div class="px-lg-0 d-flex justify-center justify-lg-start">
                <p class="font-weight-bold text-white text-uppercase follow-us">
                  {{ $t("menu.followUs") }}
                </p>
                <div class="icon-social">
                  <v-btn
                    link
                    small
                    text
                    color="white"
                    class="pa-0 btn-social"
                    icon
                  >
                    <v-icon dark>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn link small color="white" class="pa-0 btn-social" icon>
                    <v-icon dark>mdi-instagram</v-icon>
                  </v-btn>
                </div>
                <span class="white ml-1 px-3 rounded-xl font-weight-bold"
                  >@mixartdo</span
                >
              </div>
            </v-col>
            <v-col ccols="12" xs="12" lg="6" class="pr-0 pt-0">
              <v-row class="mt-0 mr-lg-4 justify-lg-end justify-center">
                <span
                  class="text-white ml-lg-1 font-weight-light text-body-2 text-center text-lg-right"
                  >{{ $t("menu.copyright") }}</span
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "footer-component",
  computed: {
    ...mapGetters(["getLinks"])
  }
};
</script>

<style></style>
