import contactsService from "../../services/contacts";

// initial state
const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  async sendEmailMessage(store, data) {
    await contactsService.sendEmailMessage(data);
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
