const messages = {
  servicesDescription: "All you need to grow your business",
  ourServices:
    "Learn about the services we offer to promote </br> <span class=''>your project or business to the next level.</span>",
  developmentTitle: "Development",
  developmentDescription:
    "We offer website creation services either for use on the Internet or intranet, where you can share information about the company or person who wants the platform. This combined with mobile applications for a better user experience.",
  designTitle: "Graphic design",
  designDescription:
    "We provide graphic design solutions for your project, brand or business. We work with the design of corporate image, templates for the management of social networks, advertising arts, even the graphic construction of your website.",
  asesoryTitle: "Consultancy",
  asesoryDescription:
    "We have a highly qualified staff, capable of provide you timely information, to bring about the forefront of technology for your platforms. This way you can optimize your website to enhance its functionality and positioning.",
  btnRequestInfo: "Request information",
  formRequestInfo:
    "https://docs.google.com/forms/d/1xOoFK90T4h4bL_DOE3uLNBBucK9i9oxnSfQbvul-vHE/edit",
  technologyTitle: "Technologies Used",
  technologyDescription:
    "For the development of our products we make use of various technologies in order to adapt to the needs of our clients as well as to keep up with the times.",
  hostingTitle:
    "LEARN ABOUT <span class='text-lg-h2 text-h5 font-weight-bold'>HOSTING <br/> AND DOMAIN SERVICES</span> ",
  hostingDescription:
    "We have plans adapted to the different needs of the market,</br>as well as a wide variety of domains that will allow you to </br>orient your space on the web according to your objectives.",
  webDevelopment: "Website Development",
  appDevelopment: "Applications development",
  webDesign: "Web design",
  imageCorpDesign: "Enterprise image desing",
  contentDesign: "Content design for social network",
  asesoryTechnology: "Technological Consulting",
  seoAsesory: "SEO and SEM consulting",
  analysisDataAsesory: "Data analysis in Power BI",
  strategicAlly: "Looking for a strategic ally?",
  strategicAllyDescription:
    "Take your project to the next level. We have a trained team, capable of offering technological support in the development of digital tools that allow you to expand your business. Contact us to expand details about this and other services.",
  btnKnowMore: "Know more",
  ourLatestProjectsTitle: "Know some of our <br><span>Latest Projects</span>",
  btnShow: "Show",
  btnContactUs: "Keep in touch",
  strategicAlliesTitle: "We are strategic allies of",
  alliesDescription:
    "German organization of several businesses which global objective  <br/> is the design and sale of high quality integrated systems, <br/>  in the same way as data analysis tools."
};
export default messages;
