const messages = {
  servicesDescription:
    "Todas las piezas que necesitas para hacer crecer tu negocio",
  ourServices:
    "Conoce los servicios que ofrecemos para impulsar </br> <span>tu proyecto o negocio al siguiente nivel.</span>",
  developmentTitle: "Desarrollo",
  developmentDescription:
    "Ofrecemos servicios de creación de sitios web tanto para su uso en Internet o intranet, donde podrá compartir información sobre la empresa o persona que desee la plataforma. Esto combinado con aplicaciones móviles para una mejor experiencia de usuario.",
  designTitle: "Diseño gráfico",
  designDescription:
    "Brindamos soluciones de diseño gráfico para tu proyecto, marca o negocio. Trabajamos con el diseño de imagen corporativa, plantillas para el manejo de redes sociales, artes publicitarios, hasta la construcción gráfica de tu sitio web.  ",
  asesoryTitle: "Asesorías",
  asesoryDescription:
    "Contamos con un personal altamente calificado, capaz de brindarte información oportuna, con la cual sus plataformas a la vanguardia de la tecnología. De este modo podrás optimizar tu sitio web para mejorar su funcionalidad y posicionamiento.",
  btnRequestInfo: "Solicitar información",
  formRequestInfo:
    "https://docs.google.com/forms/d/e/1FAIpQLSffVR18_OkLRww4EmoTScI1T0PwCYN3nrk7Jy1ZkRiHcCmBYw/viewform",
  technologyTitle: "Tecnologías Utilizadas",
  technologyDescription:
    "Para el desarrollo de nuestros productos hacemos uso de diversas tecnologías a fin de adaptarnos a la necesidades de nuestros clientes así como lograr mantenernos a la venguardia de los tiempos.",
  hostingTitle:
    "CONOCE NUESTROS SERVICIOS DE <br><span class='text-lg-h2 text-h5 font-weight-bold'>HOSTING Y DOMINIO</span>",
  hostingDescription:
    "Contamos con planes adaptados a las diferentes necesidades del </br> mercado, así como una amplia variedad de dominios que permitirán </br> orientar su espacio en la web según tus objetivos.",
  webDevelopment: "Desarrollo de Sitios Web",
  appDevelopment: "Desarrollo de Aplicaciones",
  webDesign: "Diseño Web",
  imageCorpDesign: "Diseño de Imagen Corporativa",
  contentDesign: "Diseño de contenido para redes sociales",
  asesoryTechnology: "Asesorías Tecnológicas",
  seoAsesory: "Asesorías SEO y SEM",
  analysisDataAsesory: "Análisis de datos en Power BI",
  strategicAlly: "¿Buscando un aliado estratégico?",
  strategicAllyDescription:
    "Impulsa tu proyecto al siguiente nivel. Contamos con un equipo capacitado, capaz de ofrecer soporte tecnológico en el desarrollo de herramientas digitales que le permitan expandir su negocio. Contáctanos para ampliar detalles sobre éste y otros servicios.",
  btnKnowMore: "Conoce más",
  ourLatestProjectsTitle:
    "Conoce Algunos de Nuestros <br> <span>Últimos Proyectos</span>",
  btnShow: "Ver",
  btnContactUs: "Contáctanos",
  strategicAlliesTitle: "Somos aliados estratégicos de",
  alliesDescription:
    "Consorcio de origen alemán que tiene como objetivo global el <br/> diseño y venta de sistemas integrados de alta calidad, así como de<br/> herramientas de análisis de datos."
};
export default messages;
