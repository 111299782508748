import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/app.scss";
import "./assets/transition.scss";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { i18n } from "./plugins/i18n";
import FlagIcon from "vue-flag-icon";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueFullPage from "vue-fullpage.js";
import ViewsMixin from "./mixins/ViewMixin.vue";
import HomeBanner from "./components/viewBanners/HomeBanner.vue";
import AboutBanner from "./components/viewBanners/AboutBanner.vue";
import ContactBanner from "./components/viewBanners/ContactBanner.vue";
import ProjectsBanner from "./components/viewBanners/ProjectsBanner.vue";
import ServicesBanner from "./components/viewBanners/ServicesBanner.vue";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.component("Slick", Slick);
Vue.component("HomeBanner", HomeBanner);
Vue.component("AboutBanner", AboutBanner);
Vue.component("ContactBanner", ContactBanner);
Vue.component("ProjectsBanner", ProjectsBanner);
Vue.component("ServicesBanner", ServicesBanner);
Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true
});

Vue.use(FlagIcon);
Vue.use(VueFullPage);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_PUBLIC_KEY });
Vue.mixin(ViewsMixin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
