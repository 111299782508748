<template>
  <div class="textBlockCenter text-center" :class="{ active: isActive }">
    <h2 class="pb-lg-1 px-lg-16 text-uppercase text-lg-h2 text-h4 px-5 pb-5">
      {{ $t(content.title) }}
    </h2>
    <p class="pb-4 font-weight-light px-16 text-lg-h5 text-subtitle-1">
      {{ $t(content.copy) }}
    </p>
    <v-btn color="white" class="px-8" outlined>{{
      $t("menu.btnShowMore")
    }}</v-btn>
  </div>
</template>
<script>
export default {
  name: "HeaderText",
  props: {
    content: Object,
    isActive: Boolean
  }
};
</script>
<style lang="scss" scoped>
.textBlockCenter {
  position: absolute;
  left: 0;
  right: 0;

  h2,
  .v-application .text-h2 {
    font-weight: bold;
    font-size: 60px;
    color: white;
    letter-spacing: 6.65px !important;
    text-shadow: 6px 5px 8px rgba(0, 0, 0, 0.5);
  }
  .v-application .text-h2 {
    letter-spacing: 6.65px !important;
  }
  .v-application .text-subtitle-1 {
    letter-spacing: 2.3px !important;
  }
  p {
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 2.66px;
    text-align: center;
  }

  button {
    border: 1px solid #ffffff;
    border-radius: 8px;
    font-size: 13px;
    color: #ffffff;
    letter-spacing: 4.21px;
  }

  &.active {
    position: absolute;
    left: 0;
    right: unset;
    text-align: left !important;
    h2 {
      font-size: 40px;
    }

    p {
      display: none;
    }

    button {
      display: none;
    }
  }
}
</style>
