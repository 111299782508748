<template>
  <v-img
    :transition="false"
    :eager="true"
    lazy-src="/contactanos.svg"
    src="/contactanos.svg"
    :max-height="headerHeight"
    :min-height="headerHeight || '100%'"
    class="headImage "
    :class="[{ active: detailPage }]"
    :elevation="0"
    v-ripple
  >
    <v-container
      :fluid="!detailPage"
      fill-height
      id="contactanos"
      class="scene white--text"
      @click="
        !detailPage &&
          $router.push({
            name: $route.name + 'Overview',
            params: $route.params
          })
      "
    >
      <HeaderText :content="content" :isActive="detailPage" />
    </v-container>
  </v-img>
</template>

<script>
import HeaderText from "../HeaderText";
export default {
  name: "ContactBanner",
  components: {
    HeaderText
  },
  data() {
    return {
      content: {
        title: "menu.contactUs",
        copy: "contact.contactUsMessage",
        action: null
      }
    };
  }
};
</script>

<style></style>
