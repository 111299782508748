<template>
  <div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  name: "ViewLayout",
  components: { Footer }
};
</script>
