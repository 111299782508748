<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    headerHeight: {
      default: null
    },
    detailPage: {
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentTransitionPosition",
      "routingOnScrollIsBlock",
      "isOnDetailSection",
      "showFullPageLibrary",
      "getLinks"
    ]),
    $routeMeta() {
      return this.getLinks.find(link => this.$route.path.includes(link.url));
    }
  },
  methods: {
    ...mapMutations(["openDetailSection"]),
    showContent() {
      this.openDetailSection(true);
    }
  }
};
</script>
