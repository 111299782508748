import Vue from "vue";
import VueI18n from "vue-i18n";
import lang from "@/lang";

Vue.use(VueI18n);
let selectedCountry;
try {
  selectedCountry = JSON.parse(localStorage.getItem("country"));
} catch {
  selectedCountry = null;
  localStorage.removeItem("country");
}

export const i18n = new VueI18n({
  locale: selectedCountry ? selectedCountry?.locale : "es",
  fallbackLocale: "es",
  messages: lang
});
