<template>
  <v-img
    :eager="true"
    lazy
    src="/nosotros.svg"
    :max-height="headerHeight"
    :min-height="headerHeight || '100%'"
    class="headImage"
    :class="[{ active: detailPage }]"
    v-ripple
  >
    <v-container
      :fluid="!detailPage"
      fill-height
      id="nosotros"
      class="scene white--text"
      @click="
        !detailPage &&
          $router.push({
            name: $route.name + 'Overview',
            params: $route.params
          })
      "
    >
      <HeaderText :content="content" :isActive="detailPage" />
    </v-container>
  </v-img>
</template>

<script>
import HeaderText from "../HeaderText";

export default {
  name: "AboutBanner",
  components: {
    HeaderText
  },
  data() {
    return {
      content: {
        title: "menu.aboutUs",
        copy: "about.aboutUsMessage",
        action: null
      }
    };
  }
};
</script>

<style></style>
