<template>
  <v-container fluid fill-height class="section-home">
    <v-sheet
      v-for="content in contents"
      :key="content.title"
      :color="content.color"
      width="100%"
      height="100%"
      tile
    >
      <v-row
        class="
          fill-height
          mixart-slide-content
          align-lg-center
          my-16 my-sm-0
          mt-sm-8
          my-lg-0
          pt-lg-0
        "
        justify="space-between"
      >
        <v-col
          cols="12"
          xs="12"
          lg="6"
          xl="6"
          class="fill-lg-height slide-content my-8 my-sm-0"
        >
          <div class="info-box">
            <h2
              class="
                text-lg-h4 text-sm-h5 text-body-2 text-center text-lg-left
                mb-1
                grey--text
                text--darken-3
                font-weight-black
                text-uppercase
              "
            >
              {{ $t(content.title) }}
            </h2>
            <h1
              class="
                text-lg-h3 text-sm-h4 text-h5 text-lg-left text-center
                mb-4
                blue--text
                text--darken-1
                font-weight-black
                text-uppercase
              "
            >
              {{ $t(content.text) }}
            </h1>
            <div
              class="
                text-lg-h5 text-lg-h6 text-body-1 text-center text-lg-left
                grey--text
                text--darken-2
                font-weight-light
                px-8 px-lg-0
                mt-n3 mt-lg-0 ml-2
              "
            >
              {{ $t(content.paragraph) }}
            </div>
            <div class="
                text-lg-h5 text-lg-h6 text-body-1 text-center text-lg-left
                grey--text
                text--darken-2
                font-weight-light
                px-8 px-lg-0
                mt-3 ml-2
              ">
            <v-btn
              elevation="0"
              class="rounded-lg"
              :style="{ background: $routeMeta.meta.active.color }"
              width="300"
              :href="$t('services.formRequestInfo')"
              target="_blank"
            >
              <span
                class="font-weight-bold text-white text-body-2 btn btn-request-info"
              >
                {{ $t("services.btnRequestInfo") }}
              </span>
            </v-btn>
          </div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" lg="6" xl="6" class="mt-16 d-xs-none">          
          <lottie-player :src="content.animation"  background="transparent"  speed="1"  style="width: 800px; height: 600px;"  loop  autoplay></lottie-player>
          <v-img
            class="animation white mt-lg-0"
            :src="content.animation"
            alt=""
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "HomeBanner",
  data: () => ({
    model: 0,
    colors: ["white", "blue", "yellow darken-2", "red", "orange"],
    contents: [
      {
        title: "home.title",
        text: "home.text",
        paragraph: "home.paragraph",
        animation: "/banners/data.json",
        color: "#f6f6f6",
        action: {
          active: true,
          type: "button",
          content: "check it out"
        }
      }
    ]
  })
};
</script>
<style lang="scss" scoped>
.slide-content {
  position: relative;
  .info-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 0 auto auto;
    width: 630px;

    div {
      font-family: "Aller" !important;
    }
    .action-block {
      div {
        padding: 3px 15px 3px 3px;
        border-radius: 40px;
        display: inline-block;
        span {
          padding: 3px 15px 3px 15px;
          border-radius: 40px;
          display: inline-block;
        }
      }
    }
  }
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --tw-ring-shadow: 0 0 #0000;
  --tw-ring-offset-shadow: 0 0 #0000;
  border: 1px solid;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
</style>
