const messages = {
  home: "Inicio",
  projects: "Proyectos",
  services: "Servicios",
  aboutUs: "Sobre Nosotros",
  contactUs: "Contáctanos",
  followUs: "Síguenos:",
  copyright: "Mixart, SRL © Todos los derechos reservados",
  btnShowMore: "Ver Más"
};
export default messages;
