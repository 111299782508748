const messages = {
  contactUsMessage: "Lleva tu proyecto al siguiente nivel",
  contactUsDescription:
    "No existe distancia entre nosotros, <span>solo tienes que hacer click.</span>",
  name: "Nombre",
  message: "Mensaje",
  phone: "Teléfono",
  email: "Correo",
  address: "Dirección",
  btnSend: "Enviar",
  nameRequired: "El campo es requerido",
  nameMaxLength: "Debe tener menos de 60 carácteres",
  emailRequired: "El campo es requerido",
  emailIsValid: "El correo debe ser válido",
  messageRules: "El campo es requerido",
  messageMaxLength: "Debe tener menos de 450 carácteres",
  conctactMessageButton: "Aceptar",
  contactSuccessMessage: "¡Tu mensaje se ha enviado exitosamente!",
  contactSuccessDescription:
    "Estaremos respondiendo su mensaje a la mayor brevedad posible.",
  contactErrorMessage: "¡Hemos tenido un problema!",
  contactErrorDescription: "Inténtalo de nuevo más tarde",
  recaptchaMessages:
    'Este sitio está protegido por reCAPTCHA, las <a href="https://policies.google.com/privacy">políticas de de privacidad</a> de google y los <a href="https://policies.google.com/terms">Terminos de Servicios</a>'
};

export default messages;
