import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "../plugins/i18n";
import store from "../store";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "home",
    name: "Home",
    component: Home,
    meta: {
      banner: true
    }
  },
  {
    path: "projects",
    name: "Projects",
    component: {
      render(h) {
        return h("router-view");
      }
    },
    meta: {
      banner: true
    },
    children: [
      {
        name: "ProjectsOverview",
        path: "overview",
        component: () => import("../views/Projects.vue")
      }
    ]
  },
  {
    path: "services",
    name: "Services",
    meta: {
      banner: true
    },
    component: {
      render(h) {
        return h("router-view");
      }
    },
    children: [
      {
        name: "ServicesOverview",
        path: "overview",
        component: () => import("../views/Services.vue")
      }
    ]
  },
  {
    path: "about-us",
    name: "About",
    component: {
      render(h) {
        return h("router-view");
      }
    },
    meta: {
      banner: true
    },

    children: [
      {
        name: "AboutOverview",
        path: "overview",
        component: () => import("../views/About.vue")
      }
    ]
  },
  {
    path: "contact-us",
    name: "Contact",
    meta: {
      banner: true
    },
    component: {
      render(h) {
        return h("router-view");
      }
    },
    children: [
      {
        name: "ContactOverview",
        path: "overview",
        component: () => import("../views/Contact.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      redirect: "home"
    },
    {
      path: "*",
      redirect: "home"
    },
    {
      path: "/:locale(en|es)?",
      children: routes,
      component: {
        beforeRouteEnter: setLocale,
        beforeRouteUpdate: setLocale,
        render(h) {
          return h("router-view");
        }
      }
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

function setLocale(to, from, next) {
  const country = JSON.parse(localStorage.getItem("country"));
  let { locale } = to.params;
  if (!locale) {
    locale = country?.locale || "es";
  }

  if (locale != country?.locale) {
    let countryFromStore = store.getters.getCountries.find(
      country => country.locale === locale
    );
    if (countryFromStore) {
      localStorage.setItem("country", JSON.stringify(countryFromStore));
    }
  }
  i18n.locale = locale;
  next();
}

router.beforeEach((to, from, next) => {
  const routeMeta = store.getters.getLinks.find(link =>
    to.path.includes(link.url)
  );
  store.commit(
    "setCurrentTransitionPosition",
    routeMeta.meta.transition.position
  );
  store.commit("showFullPageLibrary", Boolean(to.meta.banner));
  next();
});

export default router;
