const messages = {
  home: "Home",
  projects: "Projects",
  services: "Services",
  aboutUs: "About Us",
  contactUs: "Keep in touch",
  followUs: "Follow US:",
  copyright: "All Rights Reserved.",
  btnShowMore: "Show More"
};
export default messages;
