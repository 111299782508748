<template>
  <home-banner :detailPage="true" />
</template>

<script>
export default {
  name: "Home",
  metaInfo() {
    return {
      meta: [
        { name: "description", content: this.$t("home.paragraph") },
        { name: "description", content: this.$t("about.aboutUsDescription1") },
        { name: "description", content: this.$t("about.aboutUsDescription2") }
      ]
    };
  },
  created() {
    this.$store.commit("openDetailSection", false);
  }
};
</script>
