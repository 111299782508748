const messages = {
  aboutUsMessage: "Know the team that will improve your projects",
  aboutUsDescription1:
    "We are an agency focused on supplying knowledge, tools and technological development for companies that need to evolve in the digital world. We offer a set of market leading services, from brand projections to web oriented strategic solutions and e-commerce.",
  aboutUsDescription2:
    "Our trajectory of more than 10 years in the dominican and international market represents our passion on providing quality services according to the strategic objectives of our clients.",
  ourTeam: "Our Team",
  founder: "Founder & Development Team Leader",
  coFounder: "Co-founder & Administrative Manager",
  frontEndDeveloper: "Front End Developer",
  backEndDeveloper: "Back End Developer",
  directorOfArts: "Director of Arts",
  fullStack: "Full Stack Developer",
  scrumMaster: "Scrum Master",
  qualityAnalyst: "Software Quality Analyst",
  juniorDevelop: "Junior Developer",
  dataAnalyst: "Success Data Analyst",
  graphicDesigner: "Junior Graphic Designer",
  portfolioDownload: "Download our portfolio",
  portfolio: "/portfolio.pdf",
  btnDownload: "Download",
  sysAdmin: "SysAdmin"
};

export default messages;
